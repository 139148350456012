import 'react-app-polyfill/ie11';
import React from 'react';
import ReactDOM from 'react-dom';
import ReactGA from 'react-ga';
import Config from '~/config';
// import '@australiangreens/theme/all/all.css';
import '@australiangreens/theme/fed21/all/all.css';
// import '@australiangreens/theme/qld/global.css';
// import '@australiangreens/theme/qld/fa.css';
import AppWrapper from '~/components/wrappers/AppWrapper/AppWrapper';
import * as serviceWorker from '~/serviceWorker';

ReactGA.initialize(Config.googleAnalyticsCode, { debug: Config.env === 'dev' });

ReactDOM.render(<AppWrapper />, document.getElementById('root'));

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: http://bit.ly/CRA-PWA
serviceWorker.unregister();

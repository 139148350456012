import React from 'react';
import styled from 'styled-components';
import { Title } from '@australiangreens/components';
import withConfig from '~/helpers/providers/withConfig';
import withElectorates from '~/helpers/providers/withElectorates';
import withGuesses from '~/helpers/providers/withGuesses';
import { actions } from '~/store';
import { Config } from '~/helpers/types/Config';
import { SelectedElectorates } from '~/helpers/types/Electorate';

/**
 * Subtitle which appears under main title.
 */
const Subtitle = styled.h1.attrs({ className: 'u-c-green-core' })`
  margin-top: 0;
  margin-bottom: 10px;
`;

/**
 * Button that takes the user to the Easy English voting guide, if present for this election.
 */
const EasyEnglishLink = styled.a.attrs({ className: 'btn btn-secondary btn-sm' })`
  font-size: 16px;
  cursor: pointer;
  margin-left: 5px;
`;

type HeaderProps = {
  /** HTV app configuration. May include merged config that's election-specific and app-specific. */
  config: Config;
  /** The current selected electorates, if any. */
  electorates?: object;
  /** The current guesses of the user's electorate, if none are selected. */
  guesses: any[];
};

/**
 * The header that appears at the top of the desktop HTV app.
 */
export const Header: React.SFC<HeaderProps> = ({ config, electorates, guesses }: { config: Config; electorates: SelectedElectorates; guesses: any }) => {
  /**
 * Wrapper for component, ensuring everything inside is centered.
 */
  const Wrapper = styled.div`
text-align: center;
${config.election.code === 'qld20s' ? 'margin-top: 2rem;' : ''}
`;

  /**
 * Button that allows user to go back to the electorate select screen.
 */
  const ChangeLink = styled.button.attrs({ className: `btn btn-primary btn-sm ${config.election.code === 'qld20s' ? 'mt-3' : ''}` })`
font-size: 16px;
cursor: pointer;
`;
  return (
    <Wrapper>
      <Title>{config.headerTitle ? config.headerTitle : 'How to Vote'}</Title>
      <Subtitle>in the {config.election.name}</Subtitle>
      {!electorates && guesses.length > 0 && <Subtitle>in {guesses[0].lower}?</Subtitle>}
      {electorates && (
        <>
          <Subtitle>
            in {electorates.lower.name}
            <br />
          </Subtitle>
          <ChangeLink onClick={actions.clearElectorates}>View another electorate</ChangeLink>
        </>
      )}
      {electorates
        && config.easyEnglishGuideUrl && (
        <>
          <EasyEnglishLink
            href={config.easyEnglishGuideUrl.toString()}
            target="_blank"
            rel="noopener noreferrer"
          >
              How to Vote (Easy English)
          </EasyEnglishLink>
        </>
      )}
    </Wrapper>
  );
};

Header.defaultProps = {
  electorates: null,
};

export default withGuesses(withElectorates(withConfig(Header)));

/* eslint-disable  */
import React, { Fragment, ReactElement } from 'react';
import { Col } from 'react-bootstrap';
import UpperHousePreference from '~/components/sections/HowToVote/Ballot/UpperHousePreference/UpperHousePreference';
import { BallotDesign } from '~/helpers/types/BallotDesign';
import { Instructions } from '~/helpers/types/Instructions';

import Hidden from '@material-ui/core/Hidden';

export const VIC18Ballot: BallotDesign = {
  getBallotColour: electorate => "#ffffff",
  showGreensLogoOnBallot: true,
  instructions: {
    lower: candidate => (
      <>
        You must number{' '}
        <b>
          <u>EVERY BOX</u>
        </b>{' '}
        for your vote to count.
        <b> No ticks or crosses.</b>
      </>
    ),
    upper: candidate => (
      <>
        Vote{' '}
        <b>
          <u>ABOVE THE LINE</u>
        </b>{' '}
        by putting a 1 in the box <b>AUSTRALIAN GREENS.</b>
        <br />
        Leave all other boxes <b>BLANK.</b>
      </>
    )
  }
};

export const NSW19Ballot: BallotDesign = {
  getBallotColour: electorate => "#ffffff",
  showGreensLogoOnBallot: false,
  instructions: {
    lower: candidate => (
      <>
        Number [1]{' '}
        <b>
          {candidate.firstName} {candidate.lastName} THE GREENS
        </b>
        <br />
        Then number the other boxes as indicated.
      </>
    ),
    upper: candidate => (
      <>
        Number [1] in <b>group {candidate.group}</b> above the line<br />
        Then number the other boxes above the line as indicated<br />
        No need to number the boxes below the line.
      </>
    )
  }
};

export const FED19Ballot: BallotDesign = {
  getBallotColour: electorate => electorate.type == 'State' ? '#EEF8E8' : "#ffffff",
  showGreensLogoOnBallot: false,
  lowerBallotHeadline: (
    <Fragment>
      <Hidden mdUp>Vote 1 for Climate Action</Hidden>
      <Hidden smDown>
        How to vote in<br />the House of Representatives
      </Hidden>
    </Fragment>
  ),
  upperBallotHeadline: (
    <Fragment>
      <Hidden mdUp>Vote 1 for Climate Action</Hidden>
      <Hidden smDown>
        Put the Greens first<br />in the Senate
      </Hidden>
    </Fragment>
  ),
  instructions: {
    lower: candidate => (
      <>
        On the green paper, put a [1] for{' '}
        <b>
          {candidate.lastName}, {candidate.firstName}
        </b>
        <br />
        then number all other boxes in order of your choice.
      </>
    ),
    upper: candidate => (
      <>
        Number [1] in <b>group {candidate.group}</b> above the line<br />
        Then number five more boxes above the line, from <b>2</b> to <b>6</b> as shown here.
      </>
    )
  }
};

export const QLD20Ballot: BallotDesign = {
  getBallotColour: electorate => electorate.type == 'State' ? '#F0F0FA' : "#CDEBB5",
  showGreensLogoOnBallot: false,
  showCandidateHero: true,
  labels: {
    lowerLabel: 'Local Council',
    upperLabel: ''
  },
  instructions: null
};

export const QLD20SBallot: BallotDesign = {
  getBallotColour: electorate => electorate.type == 'State' ? '#F0F0FA' : "#CDEBB5",
  showGreensLogoOnBallot: false,
  showCandidateHero: true,
  labels: {
    lowerLabel: 'Division',
    upperLabel: undefined
  },
  instructions: null
};

export const WA21Ballot: BallotDesign = {
  getBallotColour: electorate => '#ffffff',  showGreensLogoOnBallot: false,
  showCandidateHero: false,
  labels: {
    lowerLabel: 'Lower House',
    upperLabel: 'Upper House'
  },
  instructions: null
};

export const SA22Ballot: BallotDesign = {
  getBallotColour: electorate => "#cfe8ca",
  showGreensLogoOnBallot: false,
  showCandidateHero: false,
  labels: {
    lowerLabel: "House of Assembly",
    upperLabel: "Legislative Council",
  },
  instructions: null
};

export const FED22Ballot: BallotDesign = {
  getBallotColour: electorate => "#EEF8E8",
  showGreensLogoOnBallot: false,
  showCandidateHero: false,
  lowerBallotHeadline: (
    <Fragment>
      <Hidden mdUp>Vote Climate, Vote 1 Greens</Hidden>
      <Hidden smDown>
        How to vote in the House
      </Hidden>
    </Fragment>
  ),
  upperBallotHeadline: (
    <Fragment>
      <Hidden mdUp>Vote Climate, Vote 1 Greens </Hidden>
      <Hidden smDown>
        How to vote in the Senate
      </Hidden>
    </Fragment>
  ),
  instructions: {
    lower: candidate => (
      <>
        On the green paper, put a [1] for{' '}
        <b>
          {candidate.lastName}, {candidate.firstName}
        </b>
        <br />
        then number all other boxes in order of your choice.
      </>
    ),
    upper: candidate => (
      <>
        Number [1] in <b>group {candidate.group}</b> above the line<br />
        Then number five more boxes above the line, from <b>2</b> to <b>6</b> as shown here.
      </>
    )
  }
};

export const VIC22Ballot: BallotDesign = {
  getBallotColour: electorate => "#FFFFFF",
  showGreensLogoOnBallot: true,
  labels: {
    lowerLabel: 'Legislative Assembly',
    upperLabel: 'Legislative Council',
  },
  instructions: {
    lower: candidate => (
      <>
        You must number{' '}
        <b>
          <u>EVERY BOX</u>
        </b>{' '}
        for your vote to count.
        <b> No ticks or crosses.</b>
      </>
    ),
    upper: candidate => (
      <>
        Vote{' '}
        <b>
          <u>ABOVE THE LINE</u>
        </b>{' '}
        by putting a 1 in the box <b>AUSTRALIAN GREENS.</b>
        <br />
        Leave all other boxes <b>BLANK.</b>
      </>
    )
  }
};

export const NSW23Ballot: BallotDesign = {
  getBallotColour: electorate => "#ffffff",
  showGreensLogoOnBallot: false,
  lowerBallotHeadline: (
    <Fragment>
      <Hidden mdUp>Vote Climate, Vote 1 Greens</Hidden>
      <Hidden smDown>
        How to vote in the Legislative Assembly
      </Hidden>
    </Fragment>
  ),
  upperBallotHeadline: (
    <Fragment>
      <Hidden mdUp>Vote Climate, Vote 1 Greens </Hidden>
      <Hidden smDown>
        How to vote in the Legislative Council
      </Hidden>
    </Fragment>
  ),
  labels: {
    lowerLabel: 'Legislative Assembly',
    upperLabel: 'Legislative Council',
  },
  instructions: {
    lower: candidate => (
      <>
        Number [1]{' '}
        <b>
          {candidate.firstName} {candidate.lastName} THE GREENS
        </b>
        <br />
        Then number the other boxes as indicated.
      </>
    ),
    upper: candidate => (
      <>
        Number [1] in <b>group {candidate.group}</b> above the line<br />
        Then number the other boxes above the line as indicated<br />
        No need to number the boxes below the line.
      </>
    )
  }
};

export const BCC24Ballot: BallotDesign = {
  getBallotColour: electorate => "#ffffff",
  showCandidateHero: false,
  labels: {
    lowerLabel: 'Local Council',
    upperLabel: 'Mayor'
  },
  upperBallotHeadline: (
    <Fragment>
      <Hidden mdUp>How to vote Greens for Mayor</Hidden>
      <Hidden smDown>How to vote Greens for Mayor</Hidden>
    </Fragment>
  ),
  instructions: {
    lower: candidate => (
      <>
        Number [1]{' '}
        <b>
          {candidate.firstName} {candidate.lastName}<br />THE GREENS
        </b>
        <br />
        {candidate.electorate.name === "Toowoomba" ? (
          "Then number nine other boxes, according to your preference."
        ) : (
          "Then number the other boxes as indicated."
        )}
      </>
    ),
    upper: candidate => (
      <>
        Number [1]{' '}
        <b>{candidate.firstName} { candidate.lastName}<br />THE GREENS
        </b>
        <br />
      </>
    )
  }
}

export const TAS24Ballot: BallotDesign = {
  getBallotColour: electorate => "#ffffff",
  showCandidateHero: false,
  labels: {
    lowerLabel: 'District',
    upperLabel: ''
  },
  upperBallotHeadline: (
    <Fragment>
      <Hidden mdUp></Hidden>
      <Hidden smDown></Hidden>
    </Fragment>
  ),
  instructions: {
    lower: candidate => (
      <>
        Number 1 - 7 as shown
        <br />
          You must number at least 7 boxes for your vote to count.
      </>
    ),
    upper: candidate => (
      <>
        Number [1]{' '}
        <b>{candidate.firstName} { candidate.lastName}<br />THE GREENS
        </b>
        <br />
      </>
    )
  }
}
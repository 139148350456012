import React, { Component, ReactElement } from 'react';
import ReactGA from 'react-ga';
import styled from 'styled-components';
import Hidden from '@material-ui/core/Hidden';
import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import { History } from 'history';
import { Footer, LoadingIcon } from '@australiangreens/components';
import { actions } from '~/store';
import { getLocationFromBrowser } from '~/helpers/LocationHelper';
import withElection from '~/helpers/providers/withElection';
import withElectorates from '~/helpers/providers/withElectorates';
import withHistory from '~/helpers/providers/withHistory';
import withConfig from '~/helpers/providers/withConfig';
import { findElectorate, urlSafeElectorate } from '~/helpers/constants/Electorates';
import LocationSources from '~/helpers/constants/LocationSources';
import Header from '~/components/layout/Header/Header';
import AppSections from '~/components/layout/AppSections/AppSections';
import ElectorateSelect from '~/components/sections/ElectorateSelect/ElectorateSelect';
import ComingSoon from '~/components/sections/ComingSoon/ComingSoon';
import PollsAreClosed from '~/components/sections/PollsAreClosed/PollsAreClosed';
import HowToVote from '~/components/sections/HowToVote/HowToVote';
import WhereToVote from '~/components/sections/WhereToVote/WhereToVote';
import WhyVoteGreen from '~/components/sections/WhyVoteGreen/WhyVoteGreen';
import AppTabs from '~/components/layout/AppTabs/AppTabs';
import HeaderBar from '~/components/layout/HeaderBar/HeaderBar';
import ShareDialog from '~/components/sections/ShareDialog/ShareDialog';
import DevTools from '~/components/layout/DevTools/DevTools';
import { SelectedElectorates } from '~/helpers/types/Electorate';
import { Election } from '~/helpers/types/Election';
import { Config } from '~/helpers/types/Config';

declare const window: any;

/** Facebook logo icon for ???. */
const FacebookIcon = styled.a.attrs({ className: 'page-footer-social__link fa fa-facebook-official fa-2x' })`
  padding: 5px;
`;

/** Twitter logo icon for ???. */
const TwitterIcon = styled.a.attrs({ className: 'page-footer-social__link fa fa-twitter fa-2x' })`
  padding: 5px;
`;

/** WhatSapp logo icon for ???. */
const WhatsappIcon = styled.a.attrs({ className: 'page-footer-social__link fa fa-whatsapp fa-2x' })`
  padding: 5px;
`;

type AppProps = {
  /** The selected electorates, if any. */
  electorates?: SelectedElectorates;
  /** Browser history object, used for 'navigating' within the single-page app. */
  history: History;
  /** The election info. */
  election: Election;
  /** HTV app configuration. This may included merged config at the election & electorate level. */
  config: Config;
};

type AppState = {
  /**  The status of the attempt to retrive the user's location via browser geolocation API. */
  locationStatus?: string;
  /** The currently viewed section of the app. */
  currentSection: string;
  /** Should the share dialog be showing? */
  shareDialog: boolean;
  /** Should the app be showing in high-contrast mode? */
  highContrast: boolean;
  /** Reference to registered Geolocation API handler, so it can be cleared */
  geolocationHandler?: number;
};

/**
 * The entire App shell.
 * This needs to be split out as it is huge and messy.
 */
export class App extends Component<AppProps, AppState> {
  constructor(props) {
    super(props);
    this.confirmElectorate = this.confirmElectorate.bind(this);
    this.handleSectionChange = this.handleSectionChange.bind(this);
    this.share = this.share.bind(this);
    this.closeShareDialog = this.closeShareDialog.bind(this);
    this.toggleHighContrast = this.toggleHighContrast.bind(this);
    this.state = {
      currentSection: 'how',
      locationStatus: undefined,
      shareDialog: false,
      highContrast: false,
      geolocationHandler: undefined,
    };
  }

  /** Things that should run when the app first mounts. */
  componentDidMount(): void {
    this.handleHistoryChange = this.handleHistoryChange.bind(this);
    actions.getPreferences();
    const { history } = this.props;
    history.listen(this.handleHistoryChange);
    const gotElectorateFromUrl = this.handleHistoryChange(history.location);
    if (!navigator.geolocation) {
      this.setState({ locationStatus: 'NOT_SUPPORTED' });
    }
    const handleLocationError = (err) => {
      const errorCodes = {
        1: 'PERMISSION_DENIED',
        2: 'POSITION_UNAVAILABLE',
        3: 'TIMEOUT',
        4: 'NOT_SUPPORTED',
      };
      this.setState({ locationStatus: errorCodes[err.code] });
    };
    const handleLocationChange = (result) => {
      if (result.coords) {
        this.setState({ locationStatus: 'GOT_LOCATION' });
        const { latitude, longitude, accuracy } = result.coords;
        actions.guessElectorate(latitude, longitude, accuracy, LocationSources.GPS);
      }
    };
    if (!gotElectorateFromUrl) {
      const geolocationRef = getLocationFromBrowser(handleLocationChange, handleLocationError);
      if (geolocationRef) {
        this.setState({ geolocationHandler: geolocationRef });
      }
    }
    console.log(`%cHi there 👋
Looking for a party that will stand up for digital rights, online privacy, and fast modern internet? 🧑‍💻
You've come to the right place 💚`, 'background-color: #009949; color: white; font-size: 2rem; padding: 20px; font-family: sans-serif;');
  }

  /** Things that should run when props passed to component change. */
  componentDidUpdate(): void {
    const { electorates, history } = this.props;
    if (electorates && electorates.lower) {
      const path = history.location.pathname;
      const electoratePath = `/${urlSafeElectorate(electorates.lower.name)}`;
      if (path !== electoratePath) {
        history.push(electoratePath);
      }
    }
  }

  /** Handle a change of currently viewed 'page'/URL fragment. */
  /* eslint-disable class-methods-use-this */
  handleHistoryChange(location): boolean {
    ReactGA.pageview(location.pathname);
    const { electorates, config } = this.props;
    const path = location.pathname.replace('/', '');
    if (!path) {
      if (electorates) {
        actions.clearElectorates();
      }
      return false;
    }
    const pathElectorate = findElectorate(path, config.electorates.lower);
    const currentElectorate = electorates ? electorates.lower : null;
    if (!pathElectorate) {
      actions.clearElectorates();
    } else if (!currentElectorate || (pathElectorate !== currentElectorate.name)) {
      this.confirmElectorate(pathElectorate, undefined);
      return true;
    }
    return false;
  }

  /** Function called when the user manually selects or confirms their electorate. */
  confirmElectorate(electorate, guess): void {
    const { config } = this.props;
    const { geolocationHandler } = this.state;
    ReactGA.set({ dimension1: electorate });
    ReactGA.event({
      category: 'Electorate Select',
      action: `Electorate selected by ${guess ? guess.source : 'manual choice'}`,
    });
    actions.setElectoratesByLower(electorate);
    if (guess) {
      actions.setLocation(guess.lat, guess.lng);
    }
    // Disabled due to misbehaviour during SA22 election
    // window.scrollTo(0, 1);
    ReactGA.event({
      category: 'Section View',
      action: 'Viewed vote',
    });
    if (window.hj) {
      window.hj('trigger', 'how');
    } else {
      setTimeout(() => window.hj('trigger', 'how'), 5000);
    }
    if (geolocationHandler) {
      navigator.geolocation.clearWatch(geolocationHandler);
    }
  }

  /**
   * Function called when user changes viewed section.
   * Only relevant for mobile, where one section is rendered at a time.
  */
  handleSectionChange(newSection): void {
    window.scrollTo(0, 0);
    this.setState({ currentSection: newSection });
    ReactGA.event({
      category: 'Section View',
      action: `Viewed ${newSection}`,
    });
    window.hj('trigger', newSection);
  }

  /**
   * Function called when user initiates a share action.
   * Uses the navigator.share API if available, falling back to
   * rendering the ShareDialog component.
   * This appears to be a duplicate of the function in AppBarWithDrawer.
   */
  share(): void {
    const { config } = this.props;
    if (navigator.share) {
      navigator.share({
        title: 'How to Vote Greens',
        text: config.shareText,
        url: config.shareUrl?.toString(),
      });
    } else {
      this.setState({ shareDialog: true });
    }
  }

  /** Function called when the share dialog should close. */
  closeShareDialog(): void {
    this.setState({ shareDialog: false });
  }

  /**
   * Function called when the user indicates they wish to toggle the
   * 'high contrast' mode provided for accessibility.
   * Adds or removes a class to the HTML body element depending on existing state.
   * Note: State exists in two places, the presence/absence of the class on the DOM element
   * (since we just call `classList.toggle`) and the React component state.
   * In theory this is fine, as there should be no way for the two to be out of sync.
   */
  toggleHighContrast(): void {
    const { highContrast } = this.state;
    document.body.classList.toggle('accessibility-highcontrast');
    this.setState({ highContrast: !highContrast });
  }

  /**
   * Function called to render the current section.
   * Only relevant in mobile view, as all sections are displayed on desktop.
   */
  renderCurrentSection(): ReactElement {
    const { currentSection } = this.state;
    const { electorates } = this.props;
    if (!electorates) {
      return <div style={{ textAlign: 'center' }}><LoadingIcon /></div>;
    }
    switch (currentSection) {
      case 'how':
        return <HowToVote />;
      case 'where':
        return <WhereToVote />;
      case 'why':
        return <WhyVoteGreen />;
      default:
        return null;
    }
  }

  /* eslint-enable */
  render(): ReactElement {
    const { electorates, election, config } = this.props;
    const {
      currentSection, locationStatus, shareDialog, highContrast,
    } = this.state;
    const fbUrl = `https://www.facebook.com/sharer.php?u=${encodeURIComponent(config.shareUrl?.toString())}`;
    const twitterUrl = `https://twitter.com/intent/tweet?text=${encodeURIComponent(config.shareText)}&url=${encodeURIComponent(config.shareUrl?.toString())}`;
    const whatsappUrl = `whatsapp://send?text=${encodeURIComponent(`${config.shareText} ${config.shareUrl?.toString()}`)}`;
    if (config.comingSoon) {
      return <ComingSoon />;
    }
    if (election.pollsAreClosed) {
      return <PollsAreClosed />;
    }
    return (
      <>
        {process.env.HTV_ENV === 'dev' && <DevTools />}
        <ShareDialog open={shareDialog} onClose={this.closeShareDialog} />
        {!electorates && (
          <Dialog open={!electorates} maxWidth="md" PaperProps={{ style: { margin: '16px', maxHeight: 'calc(100% - 48px)' } }} fullWidth>
            <DialogContent style={{ padding: 0 }}>
              <ElectorateSelect confirmElectorate={this.confirmElectorate} locationStatus={locationStatus} />
            </DialogContent>
          </Dialog>
        )}
        <Hidden smDown>
          <Header />
          <AppSections />
        </Hidden>
        <Hidden mdUp>
          <HeaderBar electorates={electorates} />
          <AppTabs currentSection={currentSection} changeSection={this.handleSectionChange}>
            {this.renderCurrentSection()}
          </AppTabs>
        </Hidden>
        <Footer copyright={config.authorisationLine} links={config.footerLinks} theme={config.election?.code === 'qld20s' ? 'qld' : 'fed'}>
          <button type="button" className={config.election?.code === 'qld20s' ? 'btn btn-primary' : 'btn btn-white'} onClick={this.share} style={{ marginBottom: config.election?.code === 'qld20s' ? '20px' : 'initial' }}>
            Share
          </button>
        </Footer>
      </>
    );
  }
}

export default withConfig(withElection(withElectorates(withHistory(App))));
